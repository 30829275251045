export const menuItems = [
    {
        id: 0,
        label: 'Articles',
        icon: 'bx-list-ul',
        link: '/articles'
    },
    {
        id: 1,
        label: 'Benchmark Prices',
        icon: 'bx-sort-up',
        link: '/benchmarks'
    },
    {
        id: 2,
        label: 'Supplier Prices',
        icon: 'bx-list-ul',
        link: '/prices'
    },
    {
        id: 3,
        label: 'Sale Prices',
        icon: 'bx-money',
        link: '/sale-prices'
    },
    {
        id: 4,
        label: 'Price lists',
        icon: 'bx-file-blank',
        link: '/pricelists'
    },
    {
        id: 5,
        label: 'System',
        icon: 'bx-data',
        role: 'admin',
        subItems: [
            {
                id: 501,
                label: 'Users',
                link: '/users',
                parentId: 3
            },
            {
                id: 502,
                label: 'Suppliers',
                link: '/suppliers',
                parentId: 3
            },
            {
                id: 503,
                label: 'Currencies',
                link: '/currencies',
                parentId: 3
            }
        ]
    }
];

